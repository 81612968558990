<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button variant="primary" :to="{ name: 'apps-pages-add'}"><!-- apps-pages-add -->
                Novo
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchPages"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button class="w-100" variant="primary" :to="{ name: 'apps-pages-edit', params: { id: data.item.id } }">
            Gerenciar
          </b-button>
        </template>
        <!-- Column: description -->
        <template #cell(description)="data">
          <p>{{shortStr(data.item.description, 40)}}</p>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import usePagesList from './usePagesList'
import pageStoreModule from '../pageStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
  },
  setup() {
    const PAGE_APP_STORE_MODULE_NAME = 'app-page'

    // Register module
    if (!store.hasModule(PAGE_APP_STORE_MODULE_NAME)) store.registerModule(PAGE_APP_STORE_MODULE_NAME, pageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PAGE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      stepFilter,
      statusFilter,
    } = usePagesList()

    return {

      // Sidebar
      fetchPages,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      stepFilter,
      statusFilter,
    }
  },
  methods: {
    shortStr(str, length) {
      /* eslint-disable-next-line */
      let rets = (str.length > 30) ? '...' : ''
      return str.substring(0, length) + rets
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
